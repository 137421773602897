import React from "react"
import styles from "./nav-bar.module.css"
import { Link } from "gatsby"

export function NavBar() {
  return (
    <nav className={styles.NavBar}>
      <h2>Andrico</h2>
      <div aria-hidden>•</div>
      <Link activeClassName="activeNav" to="/">
        <p>portfolio</p>
      </Link>
      <div aria-hidden>•</div>
      <a href="https://blog.andri.co">
        <p>blog</p>
      </a>
      <div aria-hidden>•</div>
      <Link activeClassName="activeNav" to="/bookshelf">
        <p>bookshelf</p>
      </Link>
    </nav>
  )
}
