import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import { NavBar } from "../components/nav-bar"
import styles from "./layout.module.css"

interface Props {
  children: React.ReactNode
  title: string
}

const Layout = ({ children, title }: Props) => {
  return (
    <div className={styles.Layout}>
      <SEO title={title} />
      <NavBar />
      <div style={{ height: "42px" }} />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
